
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG148 from "./assets/Honeymoon/2022-08-08/148.jpeg";
import JPEG149 from "./assets/Honeymoon/2022-08-08/149.jpeg";
import JPEG150 from "./assets/Honeymoon/2022-08-08/150.jpeg";
import JPEG151 from "./assets/Honeymoon/2022-08-08/151.jpeg";
import JPEG152 from "./assets/Honeymoon/2022-08-08/152.jpeg";
import JPEG153 from "./assets/Honeymoon/2022-08-08/153.jpeg";
import JPEG154 from "./assets/Honeymoon/2022-08-08/154.jpeg";
import JPEG155 from "./assets/Honeymoon/2022-08-08/155.jpeg";
import JPEG156 from "./assets/Honeymoon/2022-08-08/156.jpeg";
import JPEG157 from "./assets/Honeymoon/2022-08-08/157.jpeg";
import JPEG158 from "./assets/Honeymoon/2022-08-08/158.jpeg";
import JPEG159 from "./assets/Honeymoon/2022-08-08/159.jpeg";
import JPEG160 from "./assets/Honeymoon/2022-08-08/160.jpeg";
import JPEG161 from "./assets/Honeymoon/2022-08-08/161.jpeg";
import JPEG162 from "./assets/Honeymoon/2022-08-08/162.jpeg";
import JPEG163 from "./assets/Honeymoon/2022-08-08/163.jpeg";
import JPEG164 from "./assets/Honeymoon/2022-08-08/164.jpeg";
import JPEG165 from "./assets/Honeymoon/2022-08-08/165.jpeg";
import JPEG166 from "./assets/Honeymoon/2022-08-08/166.jpeg";
import JPEG167 from "./assets/Honeymoon/2022-08-08/167.jpeg";
import JPEG168 from "./assets/Honeymoon/2022-08-08/168.jpeg";
import JPEG169 from "./assets/Honeymoon/2022-08-08/169.jpeg";
import JPEG170 from "./assets/Honeymoon/2022-08-08/170.jpeg";
import JPEG171 from "./assets/Honeymoon/2022-08-08/171.jpeg";
import JPEG172 from "./assets/Honeymoon/2022-08-08/172.jpeg";
import JPEG173 from "./assets/Honeymoon/2022-08-08/173.jpeg";
import JPEG174 from "./assets/Honeymoon/2022-08-08/174.jpeg";
import JPEG175 from "./assets/Honeymoon/2022-08-08/175.jpeg";
import JPEG176 from "./assets/Honeymoon/2022-08-08/176.jpeg";
import JPEG177 from "./assets/Honeymoon/2022-08-08/177.jpeg";
import JPEG178 from "./assets/Honeymoon/2022-08-08/178.jpeg";
import JPEG179 from "./assets/Honeymoon/2022-08-08/179.jpeg";
import JPEG180 from "./assets/Honeymoon/2022-08-08/180.jpeg";
import JPEG181 from "./assets/Honeymoon/2022-08-08/181.jpeg";
import JPEG182 from "./assets/Honeymoon/2022-08-08/182.jpeg";
import JPEG183 from "./assets/Honeymoon/2022-08-08/183.jpeg";
import JPEG184 from "./assets/Honeymoon/2022-08-08/184.jpeg";
import JPEG185 from "./assets/Honeymoon/2022-08-08/185.jpeg";
import JPEG186 from "./assets/Honeymoon/2022-08-08/186.mp4";
import JPEG187 from "./assets/Honeymoon/2022-08-08/187.jpeg";
import JPEG188 from "./assets/Honeymoon/2022-08-08/188.jpeg";
import JPEG189 from "./assets/Honeymoon/2022-08-08/189.jpeg";
import JPEG190 from "./assets/Honeymoon/2022-08-08/190.jpeg";
import JPEG191 from "./assets/Honeymoon/2022-08-08/191.jpeg";
import JPEG192 from "./assets/Honeymoon/2022-08-08/192.jpeg";
import JPEG193 from "./assets/Honeymoon/2022-08-08/193.jpeg";
import JPEG194 from "./assets/Honeymoon/2022-08-08/194.jpeg";
import JPEG195 from "./assets/Honeymoon/2022-08-08/195.jpeg";
import JPEG196 from "./assets/Honeymoon/2022-08-08/196.jpeg";
import JPEG197 from "./assets/Honeymoon/2022-08-08/197.jpeg";
import JPEG198 from "./assets/Honeymoon/2022-08-08/198.jpeg";
import JPEG199 from "./assets/Honeymoon/2022-08-08/199.jpeg";
import JPEG200 from "./assets/Honeymoon/2022-08-08/200.jpeg";
import JPEG201 from "./assets/Honeymoon/2022-08-08/201.jpeg";
import JPEG202 from "./assets/Honeymoon/2022-08-08/202.jpeg";
import JPEG203 from "./assets/Honeymoon/2022-08-08/203.jpeg";
import JPEG204 from "./assets/Honeymoon/2022-08-08/204.jpeg";
import JPEG205 from "./assets/Honeymoon/2022-08-08/205.jpeg";
import JPEG206 from "./assets/Honeymoon/2022-08-08/206.jpeg";
import JPEG207 from "./assets/Honeymoon/2022-08-08/207.jpeg";
import JPEG208 from "./assets/Honeymoon/2022-08-08/208.jpeg";
import JPEG209 from "./assets/Honeymoon/2022-08-08/209.jpeg";
import JPEG210 from "./assets/Honeymoon/2022-08-08/210.jpeg";
import JPEG211 from "./assets/Honeymoon/2022-08-08/211.jpeg";
import JPEG212 from "./assets/Honeymoon/2022-08-08/212.jpeg";
import JPEG213 from "./assets/Honeymoon/2022-08-08/213.jpeg";
import JPEG214 from "./assets/Honeymoon/2022-08-08/214.jpeg";
import JPEG215 from "./assets/Honeymoon/2022-08-08/215.jpeg";
import JPEG216 from "./assets/Honeymoon/2022-08-08/216.jpeg";
import JPEG217 from "./assets/Honeymoon/2022-08-08/217.jpeg";
import JPEG218 from "./assets/Honeymoon/2022-08-08/218.jpeg";
import JPEG219 from "./assets/Honeymoon/2022-08-08/219.jpeg";
import JPEG220 from "./assets/Honeymoon/2022-08-08/220.jpeg";
import JPEG221 from "./assets/Honeymoon/2022-08-08/221.jpeg";
import JPEG222 from "./assets/Honeymoon/2022-08-08/222.jpeg";
import JPEG223 from "./assets/Honeymoon/2022-08-08/223.jpeg";
import JPEG224 from "./assets/Honeymoon/2022-08-08/224.jpeg";
import JPEG225 from "./assets/Honeymoon/2022-08-08/225.jpeg";
import JPEG226 from "./assets/Honeymoon/2022-08-08/226.jpeg";
import JPEG227 from "./assets/Honeymoon/2022-08-08/227.jpeg";
import JPEG228 from "./assets/Honeymoon/2022-08-08/228.jpeg";
import JPEG229 from "./assets/Honeymoon/2022-08-08/229.jpeg";
import JPEG230 from "./assets/Honeymoon/2022-08-08/230.jpeg";
import JPEG231 from "./assets/Honeymoon/2022-08-08/231.jpeg";
import JPEG232 from "./assets/Honeymoon/2022-08-08/232.jpeg";
import JPEG233 from "./assets/Honeymoon/2022-08-08/233.jpeg";
import JPEG234 from "./assets/Honeymoon/2022-08-08/234.jpeg";
import JPEG235 from "./assets/Honeymoon/2022-08-08/235.jpeg";
import JPEG236 from "./assets/Honeymoon/2022-08-08/236.jpeg";
import JPEG237 from "./assets/Honeymoon/2022-08-08/237.jpeg";
import JPEG238 from "./assets/Honeymoon/2022-08-08/238.jpeg";
import JPEG239 from "./assets/Honeymoon/2022-08-08/239.jpeg";
import JPEG240 from "./assets/Honeymoon/2022-08-08/240.jpeg";
import JPEG241 from "./assets/Honeymoon/2022-08-08/241.jpeg";
import JPEG242 from "./assets/Honeymoon/2022-08-08/242.jpeg";
import JPEG243 from "./assets/Honeymoon/2022-08-08/243.jpeg";
import JPEG244 from "./assets/Honeymoon/2022-08-08/244.jpeg";
import JPEG245 from "./assets/Honeymoon/2022-08-08/245.jpeg";
import JPEG246 from "./assets/Honeymoon/2022-08-08/246.jpeg";
import JPEG247 from "./assets/Honeymoon/2022-08-08/247.jpeg";
import JPEG248 from "./assets/Honeymoon/2022-08-08/248.jpeg";
import JPEG249 from "./assets/Honeymoon/2022-08-08/249.jpeg";
import JPEG250 from "./assets/Honeymoon/2022-08-08/250.jpeg";
import JPEG251 from "./assets/Honeymoon/2022-08-08/251.jpeg";
import JPEG252 from "./assets/Honeymoon/2022-08-08/252.jpeg";
import JPEG253 from "./assets/Honeymoon/2022-08-08/253.jpeg";
import JPEG254 from "./assets/Honeymoon/2022-08-08/254.jpeg";
import JPEG255 from "./assets/Honeymoon/2022-08-08/255.jpeg";
import JPEG256 from "./assets/Honeymoon/2022-08-08/256.jpeg";
import JPEG257 from "./assets/Honeymoon/2022-08-08/257.jpeg";
import JPEG258 from "./assets/Honeymoon/2022-08-08/258.jpeg";
import JPEG259 from "./assets/Honeymoon/2022-08-08/259.jpeg";
import JPEG260 from "./assets/Honeymoon/2022-08-08/260.jpeg";
import JPEG261 from "./assets/Honeymoon/2022-08-08/261.jpeg";
import JPEG262 from "./assets/Honeymoon/2022-08-08/262.jpeg";
import JPEG263 from "./assets/Honeymoon/2022-08-08/263.jpeg";
import JPEG264 from "./assets/Honeymoon/2022-08-08/264.jpeg";
import JPEG265 from "./assets/Honeymoon/2022-08-08/265.jpeg";
import JPEG266 from "./assets/Honeymoon/2022-08-08/266.jpeg";
import JPEG267 from "./assets/Honeymoon/2022-08-08/267.jpeg";
import JPEG268 from "./assets/Honeymoon/2022-08-08/268.jpeg";
import JPEG269 from "./assets/Honeymoon/2022-08-08/269.jpeg";
import JPEG270 from "./assets/Honeymoon/2022-08-08/270.jpeg";
import JPEG271 from "./assets/Honeymoon/2022-08-08/271.jpeg";
import JPEG272 from "./assets/Honeymoon/2022-08-08/272.jpeg";
import JPEG273 from "./assets/Honeymoon/2022-08-08/273.jpeg";
import JPEG274 from "./assets/Honeymoon/2022-08-08/274.jpeg";
import JPEG275 from "./assets/Honeymoon/2022-08-08/275.mp4";
import JPEG276 from "./assets/Honeymoon/2022-08-08/276.jpeg";
import JPEG277 from "./assets/Honeymoon/2022-08-08/277.jpeg";
import JPEG278 from "./assets/Honeymoon/2022-08-08/278.jpeg";
import JPEG279 from "./assets/Honeymoon/2022-08-08/279.jpeg";
import JPEG280 from "./assets/Honeymoon/2022-08-08/280.jpeg";
import JPEG281 from "./assets/Honeymoon/2022-08-08/281.jpeg";
import JPEG282 from "./assets/Honeymoon/2022-08-08/282.jpeg";
import JPEG283 from "./assets/Honeymoon/2022-08-08/283.jpeg";
import JPEG284 from "./assets/Honeymoon/2022-08-08/284.jpeg";
import JPEG285 from "./assets/Honeymoon/2022-08-08/285.jpeg";
import JPEG286 from "./assets/Honeymoon/2022-08-08/286.jpeg";
import JPEG287 from "./assets/Honeymoon/2022-08-08/287.jpeg";
import JPEG288 from "./assets/Honeymoon/2022-08-08/288.jpeg";
import JPEG289 from "./assets/Honeymoon/2022-08-08/289.jpeg";
import JPEG290 from "./assets/Honeymoon/2022-08-08/290.jpeg";
import JPEG291 from "./assets/Honeymoon/2022-08-08/291.jpeg";
import JPEG292 from "./assets/Honeymoon/2022-08-08/292.jpeg";
import JPEG293 from "./assets/Honeymoon/2022-08-08/293.jpeg";
import JPEG294 from "./assets/Honeymoon/2022-08-08/294.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src: JPEG148,
    width: 4,
    height: 3
  },
  {
    src: JPEG149,
    width: 4,
    height: 3
  },
  {
    src: JPEG150,
    width: 4,
    height: 3
  },
  {
    src: JPEG151,
    width: 4,
    height: 3
  },
  {
    src: JPEG152,
    width: 4,
    height: 3
  },
  {
    src: JPEG153,
    width: 4,
    height: 3
  },
  {
    src: JPEG154,
    width: 4,
    height: 3
  },
  {
    src: JPEG155,
    width: 4,
    height: 3
  },
  {
    src: JPEG156,
    width: 4,
    height: 3
  },
  {
    src: JPEG157,
    width: 4,
    height: 3
  },
  {
    src: JPEG158,
    width: 4,
    height: 3
  },
  {
    src: JPEG159,
    width: 4,
    height: 3
  },
  {
    src: JPEG160,
    width: 4,
    height: 3
  },
  {
    src: JPEG161,
    width: 4,
    height: 3
  },
  {
    src: JPEG162,
    width: 4,
    height: 3
  },
  {
    src: JPEG163,
    width: 4,
    height: 3
  },
  {
    src: JPEG164,
    width: 4,
    height: 3
  },
  {
    src: JPEG165,
    width: 4,
    height: 3
  },
  {
    src: JPEG166,
    width: 4,
    height: 3
  },
  {
    src: JPEG167,
    width: 4,
    height: 3
  },
  {
    src: JPEG168,
    width: 4,
    height: 3
  },
  {
    src: JPEG169,
    width: 4,
    height: 3
  },
  {
    src: JPEG170,
    width: 4,
    height: 3
  },
  {
    src: JPEG171,
    width: 4,
    height: 3
  },
  {
    src: JPEG172,
    width: 4,
    height: 3
  },
  {
    src: JPEG173,
    width: 4,
    height: 3
  },
  {
    src: JPEG174,
    width: 4,
    height: 3
  },
  {
    src: JPEG175,
    width: 4,
    height: 3
  },
  {
    src: JPEG176,
    width: 4,
    height: 3
  },
  {
    src: JPEG177,
    width: 4,
    height: 3
  },
  {
    src: JPEG178,
    width: 4,
    height: 3
  },
  {
    src: JPEG179,
    width: 4,
    height: 3
  },
  {
    src: JPEG180,
    width: 4,
    height: 3
  },
  {
    src: JPEG181,
    width: 4,
    height: 3
  },
  {
    src: JPEG182,
    width: 4,
    height: 3
  },
  {
    src: JPEG183,
    width: 4,
    height: 3
  },
  {
    src: JPEG184,
    width: 4,
    height: 3
  },
  {
    src: JPEG185,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG186,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG187,
    width: 4,
    height: 3
  },
  {
    src: JPEG188,
    width: 4,
    height: 3
  },
  {
    src: JPEG189,
    width: 4,
    height: 3
  },
  {
    src: JPEG190,
    width: 4,
    height: 3
  },
  {
    src: JPEG191,
    width: 4,
    height: 3
  },
  {
    src: JPEG192,
    width: 4,
    height: 3
  },
  {
    src: JPEG193,
    width: 4,
    height: 3
  },
  {
    src: JPEG194,
    width: 4,
    height: 3
  },
  {
    src: JPEG195,
    width: 4,
    height: 3
  },
  {
    src: JPEG196,
    width: 4,
    height: 3
  },
  {
    src: JPEG197,
    width: 4,
    height: 3
  },
  {
    src: JPEG198,
    width: 4,
    height: 3
  },
  {
    src: JPEG199,
    width: 4,
    height: 3
  },
  {
    src: JPEG200,
    width: 4,
    height: 3
  },
  {
    src: JPEG201,
    width: 4,
    height: 3
  },
  {
    src: JPEG202,
    width: 4,
    height: 3
  },
  {
    src: JPEG203,
    width: 4,
    height: 3
  },
  {
    src: JPEG204,
    width: 4,
    height: 3
  },
  {
    src: JPEG205,
    width: 4,
    height: 3
  },
  {
    src: JPEG206,
    width: 4,
    height: 3
  },
  {
    src: JPEG207,
    width: 4,
    height: 3
  },
  {
    src: JPEG208,
    width: 4,
    height: 3
  },
  {
    src: JPEG209,
    width: 4,
    height: 3
  },
  {
    src: JPEG210,
    width: 4,
    height: 3
  },
  {
    src: JPEG211,
    width: 4,
    height: 3
  },
  {
    src: JPEG212,
    width: 4,
    height: 3
  },
  {
    src: JPEG213,
    width: 4,
    height: 3
  },
  {
    src: JPEG214,
    width: 4,
    height: 3
  },
  {
    src: JPEG215,
    width: 4,
    height: 3
  },
  {
    src: JPEG216,
    width: 4,
    height: 3
  },
  {
    src: JPEG217,
    width: 4,
    height: 3
  },
  {
    src: JPEG218,
    width: 4,
    height: 3
  },
  {
    src: JPEG219,
    width: 4,
    height: 3
  },
  {
    src: JPEG220,
    width: 4,
    height: 3
  },
  {
    src: JPEG221,
    width: 4,
    height: 3
  },
  {
    src: JPEG222,
    width: 4,
    height: 3
  },
  {
    src: JPEG223,
    width: 4,
    height: 3
  },
  {
    src: JPEG224,
    width: 4,
    height: 3
  },
  {
    src: JPEG225,
    width: 4,
    height: 3
  },
  {
    src: JPEG226,
    width: 4,
    height: 3
  },
  {
    src: JPEG227,
    width: 4,
    height: 3
  },
  {
    src: JPEG228,
    width: 4,
    height: 3
  },
  {
    src: JPEG229,
    width: 4,
    height: 3
  },
  {
    src: JPEG230,
    width: 4,
    height: 3
  },
  {
    src: JPEG231,
    width: 4,
    height: 3
  },
  {
    src: JPEG232,
    width: 4,
    height: 3
  },
  {
    src: JPEG233,
    width: 4,
    height: 3
  },
  {
    src: JPEG234,
    width: 4,
    height: 3
  },
  {
    src: JPEG235,
    width: 4,
    height: 3
  },
  {
    src: JPEG236,
    width: 4,
    height: 3
  },
  {
    src: JPEG237,
    width: 4,
    height: 3
  },
  {
    src: JPEG238,
    width: 4,
    height: 3
  },
  {
    src: JPEG239,
    width: 4,
    height: 3
  },
  {
    src: JPEG240,
    width: 4,
    height: 3
  },
  {
    src: JPEG241,
    width: 4,
    height: 3
  },
  {
    src: JPEG242,
    width: 4,
    height: 3
  },
  {
    src: JPEG243,
    width: 4,
    height: 3
  },
  {
    src: JPEG244,
    width: 4,
    height: 3
  },
  {
    src: JPEG245,
    width: 4,
    height: 3
  },
  {
    src: JPEG246,
    width: 4,
    height: 3
  },
  {
    src: JPEG247,
    width: 4,
    height: 3
  },
  {
    src: JPEG248,
    width: 4,
    height: 3
  },
  {
    src: JPEG249,
    width: 4,
    height: 3
  },
  {
    src: JPEG250,
    width: 4,
    height: 3
  },
  {
    src: JPEG251,
    width: 4,
    height: 3
  },
  {
    src: JPEG252,
    width: 4,
    height: 3
  },
  {
    src: JPEG253,
    width: 4,
    height: 3
  },
  {
    src: JPEG254,
    width: 4,
    height: 3
  },
  {
    src: JPEG255,
    width: 4,
    height: 3
  },
  {
    src: JPEG256,
    width: 4,
    height: 3
  },
  {
    src: JPEG257,
    width: 4,
    height: 3
  },
  {
    src: JPEG258,
    width: 4,
    height: 3
  },
  {
    src: JPEG259,
    width: 4,
    height: 3
  },
  {
    src: JPEG260,
    width: 4,
    height: 3
  },
  {
    src: JPEG261,
    width: 4,
    height: 3
  },
  {
    src: JPEG262,
    width: 4,
    height: 3
  },
  {
    src: JPEG263,
    width: 4,
    height: 3
  },
  {
    src: JPEG264,
    width: 4,
    height: 3
  },
  {
    src: JPEG265,
    width: 4,
    height: 3
  },
  {
    src: JPEG266,
    width: 4,
    height: 3
  },
  {
    src: JPEG267,
    width: 4,
    height: 3
  },
  {
    src: JPEG268,
    width: 4,
    height: 3
  },
  {
    src: JPEG269,
    width: 4,
    height: 3
  },
  {
    src: JPEG270,
    width: 4,
    height: 3
  },
  {
    src: JPEG271,
    width: 4,
    height: 3
  },
  {
    src: JPEG272,
    width: 4,
    height: 3
  },
  {
    src: JPEG273,
    width: 4,
    height: 3
  },
  {
    src: JPEG274,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG275,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG276,
    width: 4,
    height: 3
  },
  {
    src: JPEG277,
    width: 4,
    height: 3
  },
  {
    src: JPEG278,
    width: 4,
    height: 3
  },
  {
    src: JPEG279,
    width: 4,
    height: 3
  },
  {
    src: JPEG280,
    width: 4,
    height: 3
  },
  {
    src: JPEG281,
    width: 4,
    height: 3
  },
  {
    src: JPEG282,
    width: 4,
    height: 3
  },
  {
    src: JPEG283,
    width: 4,
    height: 3
  },
  {
    src: JPEG284,
    width: 4,
    height: 3
  },
  {
    src: JPEG285,
    width: 4,
    height: 3
  },
  {
    src: JPEG286,
    width: 4,
    height: 3
  },
  {
    src: JPEG287,
    width: 4,
    height: 3
  },
  {
    src: JPEG288,
    width: 4,
    height: 3
  },
  {
    src: JPEG289,
    width: 4,
    height: 3
  },
  {
    src: JPEG290,
    width: 4,
    height: 3
  },
  {
    src: JPEG291,
    width: 4,
    height: 3
  },
  {
    src: JPEG292,
    width: 4,
    height: 3
  },
  {
    src: JPEG293,
    width: 4,
    height: 3
  },
  {
    src: JPEG294,
    width: 4,
    height: 3
  }
];
