import * as React from 'react';
import './Style.css';
//Page Sections
import Honeymoon1 from "./Honeymoon1";
import Honeymoon2 from "./Honeymoon2";
import Honeymoon3 from "./Honeymoon3";
import Honeymoon4 from "./Honeymoon4";
import Honeymoon5 from "./Honeymoon5";
import Honeymoon6 from "./Honeymoon6";
import Honeymoon7 from "./Honeymoon7";
import Honeymoon8 from "./Honeymoon8";
import Honeymoon9 from "./Honeymoon9";

import Typography from '@mui/material/Typography';
import { Box, Tab, Tabs } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NavBar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="basic tabs example">
        <Tab label="Day 1" {...a11yProps(0)} />
        <Tab label="Day 2" {...a11yProps(1)} />
        <Tab label="Day 3" {...a11yProps(2)} />
        <Tab label="Day 4" {...a11yProps(3)} />
        <Tab label="Day 5" {...a11yProps(4)} />
        <Tab label="Day 6" {...a11yProps(5)} />
        <Tab label="Day 7" {...a11yProps(6)} />
        <Tab label="Day 8" {...a11yProps(7)} />
        <Tab label="Day 9" {...a11yProps(8)} />
      </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Honeymoon1 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Honeymoon2 />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Honeymoon3 />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Honeymoon4 />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Honeymoon5 />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Honeymoon6 />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Honeymoon7 />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Honeymoon8 />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Honeymoon9/>
      </TabPanel>
    </div>
  );
}