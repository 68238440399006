
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG1 from "./assets/Honeymoon/2022-08-03/1.jpeg";
import JPEG2 from "./assets/Honeymoon/2022-08-03/2.jpeg";
import JPEG3 from "./assets/Honeymoon/2022-08-03/3.jpeg";
import JPEG4 from "./assets/Honeymoon/2022-08-03/4.jpeg";
import JPEG5 from "./assets/Honeymoon/2022-08-03/5.jpeg";
import JPEG6 from "./assets/Honeymoon/2022-08-03/6.jpeg";
import JPEG7 from "./assets/Honeymoon/2022-08-03/7.mp4";
import JPEG8 from "./assets/Honeymoon/2022-08-03/8.jpeg";
import JPEG9 from "./assets/Honeymoon/2022-08-03/9.mp4";
import JPEG10 from "./assets/Honeymoon/2022-08-03/10.jpeg";
import JPEG11 from "./assets/Honeymoon/2022-08-03/11.jpeg";
import JPEG12 from "./assets/Honeymoon/2022-08-03/12.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src: JPEG1,
    width: 4,
    height: 3
  },
  {
    src: JPEG2,
    width: 4,
    height: 3
  },
  {
    src: JPEG3,
    width: 4,
    height: 3
  },
  {
    src: JPEG4,
    width: 4,
    height: 3
  },
  {
    src: JPEG5,
    width: 4,
    height: 3
  },
  {
    src: JPEG6,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG7,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG8,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG9,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG10,
    width: 4,
    height: 3
  },
  {
    src: JPEG11,
    width: 4,
    height: 3
  },
  {
    src: JPEG12,
    width: 4,
    height: 3
  }
];
