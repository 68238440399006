

import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG13 from "./assets/Honeymoon/2022-08-04/13.jpeg";
import JPEG14 from "./assets/Honeymoon/2022-08-04/14.jpeg";
import JPEG15 from "./assets/Honeymoon/2022-08-04/15.jpeg";
import JPEG16 from "./assets/Honeymoon/2022-08-04/16.jpeg";
import JPEG17 from "./assets/Honeymoon/2022-08-04/17.jpeg";
import JPEG18 from "./assets/Honeymoon/2022-08-04/18.jpeg";
import JPEG19 from "./assets/Honeymoon/2022-08-04/19.jpeg";
import JPEG20 from "./assets/Honeymoon/2022-08-04/20.jpeg";
import JPEG21 from "./assets/Honeymoon/2022-08-04/21.jpeg";
import JPEG22 from "./assets/Honeymoon/2022-08-04/22.jpeg";
import JPEG23 from "./assets/Honeymoon/2022-08-04/23.jpeg";
import JPEG24 from "./assets/Honeymoon/2022-08-04/24.jpeg";
import JPEG25 from "./assets/Honeymoon/2022-08-04/25.jpeg";
import JPEG26 from "./assets/Honeymoon/2022-08-04/26.jpeg";
import JPEG27 from "./assets/Honeymoon/2022-08-04/27.jpeg";
import JPEG28 from "./assets/Honeymoon/2022-08-04/28.jpeg";
import JPEG29 from "./assets/Honeymoon/2022-08-04/29.jpeg";
import JPEG30 from "./assets/Honeymoon/2022-08-04/30.jpeg";
import JPEG31 from "./assets/Honeymoon/2022-08-04/31.jpeg";
import JPEG32 from "./assets/Honeymoon/2022-08-04/32.jpeg";
import JPEG33 from "./assets/Honeymoon/2022-08-04/33.jpeg";
import JPEG34 from "./assets/Honeymoon/2022-08-04/34.jpeg";
import JPEG35 from "./assets/Honeymoon/2022-08-04/35.jpeg";
import JPEG36 from "./assets/Honeymoon/2022-08-04/36.jpeg";
import JPEG37 from "./assets/Honeymoon/2022-08-04/37.jpeg";
import JPEG38 from "./assets/Honeymoon/2022-08-04/38.jpeg";
import JPEG39 from "./assets/Honeymoon/2022-08-04/39.jpeg";
import JPEG40 from "./assets/Honeymoon/2022-08-04/40.jpeg";
import JPEG41 from "./assets/Honeymoon/2022-08-04/41.jpeg";
import JPEG42 from "./assets/Honeymoon/2022-08-04/42.jpeg";
import JPEG43 from "./assets/Honeymoon/2022-08-04/43.jpeg";
import JPEG44 from "./assets/Honeymoon/2022-08-04/44.jpeg";
import JPEG45 from "./assets/Honeymoon/2022-08-04/45.jpeg";
import JPEG46 from "./assets/Honeymoon/2022-08-04/46.jpeg";
import JPEG47 from "./assets/Honeymoon/2022-08-04/47.jpeg";
import JPEG48 from "./assets/Honeymoon/2022-08-04/48.jpeg";
import JPEG49 from "./assets/Honeymoon/2022-08-04/49.jpeg";
import JPEG50 from "./assets/Honeymoon/2022-08-04/50.jpeg";
import JPEG51 from "./assets/Honeymoon/2022-08-04/51.jpeg";
import JPEG52 from "./assets/Honeymoon/2022-08-04/52.jpeg";
import JPEG53 from "./assets/Honeymoon/2022-08-04/53.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src:JPEG13,
    width: 4,
    height: 3
  },
  {
    src:JPEG14,
    width: 4,
    height: 3
  },
  {
    src:JPEG15,
    width: 4,
    height: 3
  },
  {
    src:JPEG16,
    width: 4,
    height: 3
  },
  {
    src:JPEG17,
    width: 4,
    height: 3
  },
  {
    src:JPEG18,
    width: 4,
    height: 3
  },
  {
    src:JPEG19,
    width: 4,
    height: 3
  },
  {
    src:JPEG20,
    width: 4,
    height: 3
  },
  {
    src:JPEG21,
    width: 4,
    height: 3
  },
  {
    src:JPEG22,
    width: 4,
    height: 3
  },
  {
    src:JPEG23,
    width: 4,
    height: 3
  },
  {
    src:JPEG24,
    width: 4,
    height: 3
  },
  {
    src:JPEG25,
    width: 4,
    height: 3
  },
  {
    src:JPEG26,
    width: 4,
    height: 3
  },
  {
    src:JPEG27,
    width: 4,
    height: 3
  },
  {
    src:JPEG28,
    width: 4,
    height: 3
  },
  {
    src:JPEG29,
    width: 4,
    height: 3
  },
  {
    src:JPEG30,
    width: 4,
    height: 3
  },
  {
    src:JPEG31,
    width: 4,
    height: 3
  },
  {
    src:JPEG32,
    width: 4,
    height: 3
  },
  {
    src:JPEG33,
    width: 4,
    height: 3
  },
  {
    src:JPEG34,
    width: 4,
    height: 3
  },
  {
    src:JPEG35,
    width: 4,
    height: 3
  },
  {
    src:JPEG36,
    width: 4,
    height: 3
  },
  {
    src:JPEG37,
    width: 4,
    height: 3
  },
  {
    src:JPEG38,
    width: 4,
    height: 3
  },
  {
    src:JPEG39,
    width: 4,
    height: 3
  },
  {
    src:JPEG40,
    width: 4,
    height: 3
  },
  {
    src:JPEG41,
    width: 4,
    height: 3
  },
  {
    src:JPEG42,
    width: 4,
    height: 3
  },
  {
    src:JPEG43,
    width: 4,
    height: 3
  },
  {
    src:JPEG44,
    width: 4,
    height: 3
  },
  {
    src:JPEG45,
    width: 4,
    height: 3
  },
  {
    src:JPEG46,
    width: 4,
    height: 3
  },
  {
    src:JPEG47,
    width: 4,
    height: 3
  },
  {
    src:JPEG48,
    width: 4,
    height: 3
  },
  {
    src:JPEG49,
    width: 4,
    height: 3
  },
  {
    src:JPEG50,
    width: 4,
    height: 3
  },
  {
    src:JPEG51,
    width: 4,
    height: 3
  },
  {
    src:JPEG52,
    width: 4,
    height: 3
  },
  {
    src:JPEG53,
    width: 4,
    height: 3
  }
];
