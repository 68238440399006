

import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG118 from "./assets/Honeymoon/2022-08-06/118.jpeg";
import JPEG119 from "./assets/Honeymoon/2022-08-06/119.jpeg";
import JPEG120 from "./assets/Honeymoon/2022-08-06/120.jpeg";
import JPEG121 from "./assets/Honeymoon/2022-08-06/121.jpeg";
import JPEG122 from "./assets/Honeymoon/2022-08-06/122.jpeg";
import JPEG123 from "./assets/Honeymoon/2022-08-06/123.jpeg";
import JPEG124 from "./assets/Honeymoon/2022-08-06/124.jpeg";
import JPEG125 from "./assets/Honeymoon/2022-08-06/125.jpeg";
import JPEG126 from "./assets/Honeymoon/2022-08-06/126.jpeg";
import JPEG127 from "./assets/Honeymoon/2022-08-06/127.jpeg";
import JPEG128 from "./assets/Honeymoon/2022-08-06/128.jpeg";
import JPEG129 from "./assets/Honeymoon/2022-08-06/129.jpeg";
import JPEG130 from "./assets/Honeymoon/2022-08-06/130.jpeg";
import JPEG131 from "./assets/Honeymoon/2022-08-06/131.jpeg";
import JPEG132 from "./assets/Honeymoon/2022-08-06/132.jpeg";
import JPEG133 from "./assets/Honeymoon/2022-08-06/133.jpeg";
import JPEG134 from "./assets/Honeymoon/2022-08-06/134.jpeg"
import JPEG135 from "./assets/Honeymoon/2022-08-06/135.jpeg";
import JPEG136 from "./assets/Honeymoon/2022-08-06/136.jpeg";
import JPEG137 from "./assets/Honeymoon/2022-08-06/137.mp4";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src:JPEG118,
    width: 4,
    height: 3
  },
  {
    src:JPEG119,
    width: 4,
    height: 3
  },
  {
    src:JPEG120,
    width: 4,
    height: 3
  },
  {
    src:JPEG121,
    width: 4,
    height: 3
  },
  {
    src:JPEG122,
    width: 4,
    height: 3
  },
  {
    src:JPEG123,
    width: 4,
    height: 3
  },
  {
    src:JPEG124,
    width: 4,
    height: 3
  },
  {
    src:JPEG125,
    width: 4,
    height: 3
  },
  {
    src:JPEG126,
    width: 4,
    height: 3
  },
  {
    src:JPEG127,
    width: 4,
    height: 3
  },
  {
    src:JPEG128,
    width: 4,
    height: 3
  },
  {
    src:JPEG129,
    width: 4,
    height: 3
  },
  {
    src:JPEG130,
    width: 4,
    height: 3
  },
  {
    src:JPEG131,
    width: 4,
    height: 3
  },
  {
    src:JPEG132,
    width: 4,
    height: 3
  },
  {
    src:JPEG133,
    width: 4,
    height: 3
  },
  {
    src:JPEG134,
    width: 4,
    height: 3
  },
  {
    src:JPEG135,
    width: 4,
    height: 3
  },
  {
    src:JPEG136,
    width: 4,
    height: 3
  }/*,
  {
    src:JPEG137,
    width: 4,
    height: 3
  }*/
];
