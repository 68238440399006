
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG547 from "./assets/Honeymoon/2022-08-11/547.jpeg";
import JPEG548 from "./assets/Honeymoon/2022-08-11/548.jpeg";
import JPEG549 from "./assets/Honeymoon/2022-08-11/549.jpeg";
import JPEG550 from "./assets/Honeymoon/2022-08-11/550.jpeg";
import JPEG551 from "./assets/Honeymoon/2022-08-11/551.jpeg";
import JPEG552 from "./assets/Honeymoon/2022-08-11/552.jpeg";
import JPEG553 from "./assets/Honeymoon/2022-08-11/553.jpeg";
import JPEG554 from "./assets/Honeymoon/2022-08-11/554.jpeg";
import JPEG555 from "./assets/Honeymoon/2022-08-11/555.jpeg";
import JPEG556 from "./assets/Honeymoon/2022-08-11/556.jpeg";
import JPEG557 from "./assets/Honeymoon/2022-08-11/557.jpeg";
import JPEG558 from "./assets/Honeymoon/2022-08-11/558.jpeg";
import JPEG559 from "./assets/Honeymoon/2022-08-11/559.jpeg";
import JPEG560 from "./assets/Honeymoon/2022-08-11/560.jpeg";
import JPEG561 from "./assets/Honeymoon/2022-08-11/561.jpeg";
import JPEG562 from "./assets/Honeymoon/2022-08-11/562.jpeg";
import JPEG563 from "./assets/Honeymoon/2022-08-11/563.jpeg";
import JPEG564 from "./assets/Honeymoon/2022-08-11/564.jpeg";
import JPEG565 from "./assets/Honeymoon/2022-08-11/565.jpeg";
import JPEG566 from "./assets/Honeymoon/2022-08-11/566.jpeg";
import JPEG567 from "./assets/Honeymoon/2022-08-11/567.jpeg";
import JPEG568 from "./assets/Honeymoon/2022-08-11/568.jpeg";
import JPEG569 from "./assets/Honeymoon/2022-08-11/569.jpeg";
import JPEG570 from "./assets/Honeymoon/2022-08-11/570.jpeg";
import JPEG571 from "./assets/Honeymoon/2022-08-11/571.jpeg";
import JPEG572 from "./assets/Honeymoon/2022-08-11/572.jpeg";
import JPEG573 from "./assets/Honeymoon/2022-08-11/573.jpeg";
import JPEG574 from "./assets/Honeymoon/2022-08-11/574.jpeg";
import JPEG575 from "./assets/Honeymoon/2022-08-11/575.jpeg";
import JPEG576 from "./assets/Honeymoon/2022-08-11/576.jpeg";
import JPEG577 from "./assets/Honeymoon/2022-08-11/577.jpeg";
import JPEG578 from "./assets/Honeymoon/2022-08-11/578.jpeg";
import JPEG579 from "./assets/Honeymoon/2022-08-11/579.jpeg";
import JPEG580 from "./assets/Honeymoon/2022-08-11/580.jpeg";
import JPEG581 from "./assets/Honeymoon/2022-08-11/581.jpeg";
import JPEG582 from "./assets/Honeymoon/2022-08-11/582.jpeg";
import JPEG583 from "./assets/Honeymoon/2022-08-11/583.jpeg";
import JPEG584 from "./assets/Honeymoon/2022-08-11/584.jpeg";
import JPEG585 from "./assets/Honeymoon/2022-08-11/585.jpeg";
import JPEG586 from "./assets/Honeymoon/2022-08-11/586.jpeg";
import JPEG587 from "./assets/Honeymoon/2022-08-11/587.jpeg";
import JPEG588 from "./assets/Honeymoon/2022-08-11/588.jpeg";
import JPEG589 from "./assets/Honeymoon/2022-08-11/589.jpeg";
import JPEG590 from "./assets/Honeymoon/2022-08-11/590.jpeg";
import JPEG591 from "./assets/Honeymoon/2022-08-11/591.jpeg";
import JPEG592 from "./assets/Honeymoon/2022-08-11/592.jpeg";
import JPEG593 from "./assets/Honeymoon/2022-08-11/593.jpeg";
import JPEG594 from "./assets/Honeymoon/2022-08-11/594.jpeg";
import JPEG595 from "./assets/Honeymoon/2022-08-11/595.jpeg";
import JPEG596 from "./assets/Honeymoon/2022-08-11/596.jpeg";
import JPEG597 from "./assets/Honeymoon/2022-08-11/597.jpeg";
import JPEG598 from "./assets/Honeymoon/2022-08-11/598.jpeg";
import JPEG599 from "./assets/Honeymoon/2022-08-11/599.jpeg";
import JPEG600 from "./assets/Honeymoon/2022-08-11/600.jpeg";
import JPEG601 from "./assets/Honeymoon/2022-08-11/601.jpeg";
import JPEG602 from "./assets/Honeymoon/2022-08-11/602.jpeg";
import JPEG603 from "./assets/Honeymoon/2022-08-11/603.jpeg";
import JPEG604 from "./assets/Honeymoon/2022-08-11/604.jpeg";
import JPEG605 from "./assets/Honeymoon/2022-08-11/605.jpeg";
import JPEG606 from "./assets/Honeymoon/2022-08-11/606.jpeg";
import JPEG607 from "./assets/Honeymoon/2022-08-11/607.jpeg";
import JPEG608 from "./assets/Honeymoon/2022-08-11/608.jpeg";
import JPEG609 from "./assets/Honeymoon/2022-08-11/609.jpeg";
import JPEG610 from "./assets/Honeymoon/2022-08-11/610.jpeg";
import JPEG611 from "./assets/Honeymoon/2022-08-11/611.jpeg";
import JPEG612 from "./assets/Honeymoon/2022-08-11/612.jpeg";
import JPEG613 from "./assets/Honeymoon/2022-08-11/613.jpeg";
import JPEG614 from "./assets/Honeymoon/2022-08-11/614.jpeg";
import JPEG615 from "./assets/Honeymoon/2022-08-11/615.jpeg";
import JPEG616 from "./assets/Honeymoon/2022-08-11/616.jpeg";
import JPEG617 from "./assets/Honeymoon/2022-08-11/617.jpeg";
import JPEG618 from "./assets/Honeymoon/2022-08-11/618.jpeg";
import JPEG619 from "./assets/Honeymoon/2022-08-11/619.jpeg";
import JPEG620 from "./assets/Honeymoon/2022-08-11/620.jpeg";
import JPEG621 from "./assets/Honeymoon/2022-08-11/621.jpeg";
import JPEG622 from "./assets/Honeymoon/2022-08-11/622.jpeg";
import JPEG623 from "./assets/Honeymoon/2022-08-11/623.jpeg";
import JPEG624 from "./assets/Honeymoon/2022-08-11/624.jpeg";
import JPEG625 from "./assets/Honeymoon/2022-08-11/625.jpeg";
import JPEG626 from "./assets/Honeymoon/2022-08-11/626.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src: JPEG547,
    width: 4,
    height: 3
  },
  {
    src: JPEG548,
    width: 4,
    height: 3
  },
  {
    src: JPEG549,
    width: 4,
    height: 3
  },
  {
    src: JPEG550,
    width: 4,
    height: 3
  },
  {
    src: JPEG551,
    width: 4,
    height: 3
  },
  {
    src: JPEG552,
    width: 4,
    height: 3
  },
  {
    src: JPEG553,
    width: 4,
    height: 3
  },
  {
    src: JPEG554,
    width: 4,
    height: 3
  },
  {
    src: JPEG555,
    width: 4,
    height: 3
  },
  {
    src: JPEG556,
    width: 4,
    height: 3
  },
  {
    src: JPEG557,
    width: 4,
    height: 3
  },
  {
    src: JPEG558,
    width: 4,
    height: 3
  },
  {
    src: JPEG559,
    width: 4,
    height: 3
  },
  {
    src: JPEG560,
    width: 4,
    height: 3
  },
  {
    src: JPEG561,
    width: 4,
    height: 3
  },
  {
    src: JPEG562,
    width: 4,
    height: 3
  },
  {
    src: JPEG563,
    width: 4,
    height: 3
  },
  {
    src: JPEG564,
    width: 4,
    height: 3
  },
  {
    src: JPEG565,
    width: 4,
    height: 3
  },
  {
    src: JPEG566,
    width: 4,
    height: 3
  },
  {
    src: JPEG567,
    width: 4,
    height: 3
  },
  {
    src: JPEG568,
    width: 4,
    height: 3
  },
  {
    src: JPEG569,
    width: 4,
    height: 3
  },
  {
    src: JPEG570,
    width: 4,
    height: 3
  },
  {
    src: JPEG571,
    width: 4,
    height: 3
  },
  {
    src: JPEG572,
    width: 4,
    height: 3
  },
  {
    src: JPEG573,
    width: 4,
    height: 3
  },
  {
    src: JPEG574,
    width: 4,
    height: 3
  },
  {
    src: JPEG575,
    width: 4,
    height: 3
  },
  {
    src: JPEG576,
    width: 4,
    height: 3
  },
  {
    src: JPEG577,
    width: 4,
    height: 3
  },
  {
    src: JPEG578,
    width: 4,
    height: 3
  },
  {
    src: JPEG579,
    width: 4,
    height: 3
  },
  {
    src: JPEG580,
    width: 4,
    height: 3
  },
  {
    src: JPEG581,
    width: 4,
    height: 3
  },
  {
    src: JPEG582,
    width: 4,
    height: 3
  },
  {
    src: JPEG583,
    width: 4,
    height: 3
  },
  {
    src: JPEG584,
    width: 4,
    height: 3
  },
  {
    src: JPEG585,
    width: 4,
    height: 3
  },
  {
    src: JPEG586,
    width: 4,
    height: 3
  },
  {
    src: JPEG587,
    width: 4,
    height: 3
  },
  {
    src: JPEG588,
    width: 4,
    height: 3
  },
  {
    src: JPEG589,
    width: 4,
    height: 3
  },
  {
    src: JPEG590,
    width: 4,
    height: 3
  },
  {
    src: JPEG591,
    width: 4,
    height: 3
  },
  {
    src: JPEG592,
    width: 4,
    height: 3
  },
  {
    src: JPEG593,
    width: 4,
    height: 3
  },
  {
    src: JPEG594,
    width: 4,
    height: 3
  },
  {
    src: JPEG595,
    width: 4,
    height: 3
  },
  {
    src: JPEG596,
    width: 4,
    height: 3
  },
  {
    src: JPEG597,
    width: 4,
    height: 3
  },
  {
    src: JPEG598,
    width: 4,
    height: 3
  },
  {
    src: JPEG599,
    width: 4,
    height: 3
  },
  {
    src: JPEG600,
    width: 4,
    height: 3
  },
  {
    src: JPEG601,
    width: 4,
    height: 3
  },
  {
    src: JPEG602,
    width: 4,
    height: 3
  },
  {
    src: JPEG603,
    width: 4,
    height: 3
  },
  {
    src: JPEG604,
    width: 4,
    height: 3
  },
  {
    src: JPEG605,
    width: 4,
    height: 3
  },
  {
    src: JPEG606,
    width: 4,
    height: 3
  },
  {
    src: JPEG607,
    width: 4,
    height: 3
  },
  {
    src: JPEG608,
    width: 4,
    height: 3
  },
  {
    src: JPEG609,
    width: 4,
    height: 3
  },
  {
    src: JPEG610,
    width: 4,
    height: 3
  },
  {
    src: JPEG611,
    width: 4,
    height: 3
  },
  {
    src: JPEG612,
    width: 4,
    height: 3
  },
  {
    src: JPEG613,
    width: 4,
    height: 3
  },
  {
    src: JPEG614,
    width: 4,
    height: 3
  },
  {
    src: JPEG615,
    width: 4,
    height: 3
  },
  {
    src: JPEG616,
    width: 4,
    height: 3
  },
  {
    src: JPEG617,
    width: 4,
    height: 3
  },
  {
    src: JPEG618,
    width: 4,
    height: 3
  },
  {
    src: JPEG619,
    width: 4,
    height: 3
  },
  {
    src: JPEG620,
    width: 4,
    height: 3
  },
  {
    src: JPEG621,
    width: 4,
    height: 3
  },
  {
    src: JPEG622,
    width: 4,
    height: 3
  },
  {
    src: JPEG623,
    width: 4,
    height: 3
  },
  {
    src: JPEG624,
    width: 4,
    height: 3
  },
  {
    src: JPEG625,
    width: 4,
    height: 3
  },
  {
    src: JPEG626,
    width: 4,
    height: 3
  }
];
