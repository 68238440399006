
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG295 from "./assets/Honeymoon/2022-08-09/295.jpeg";
import JPEG296 from "./assets/Honeymoon/2022-08-09/296.jpeg";
import JPEG297 from "./assets/Honeymoon/2022-08-09/297.jpeg";
import JPEG298 from "./assets/Honeymoon/2022-08-09/298.jpeg";
import JPEG299 from "./assets/Honeymoon/2022-08-09/299.jpeg";
import JPEG300 from "./assets/Honeymoon/2022-08-09/300.jpeg";
import JPEG301 from "./assets/Honeymoon/2022-08-09/301.jpeg";
import JPEG302 from "./assets/Honeymoon/2022-08-09/302.jpeg";
import JPEG303 from "./assets/Honeymoon/2022-08-09/303.jpeg";
import JPEG304 from "./assets/Honeymoon/2022-08-09/304.jpeg";
import JPEG305 from "./assets/Honeymoon/2022-08-09/305.jpeg";
import JPEG306 from "./assets/Honeymoon/2022-08-09/306.jpeg";
import JPEG307 from "./assets/Honeymoon/2022-08-09/307.mp4";
import JPEG308 from "./assets/Honeymoon/2022-08-09/308.jpeg";
import JPEG309 from "./assets/Honeymoon/2022-08-09/309.mp4";
import JPEG310 from "./assets/Honeymoon/2022-08-09/310.mp4";
import JPEG311 from "./assets/Honeymoon/2022-08-09/311.jpeg";
import JPEG312 from "./assets/Honeymoon/2022-08-09/312.jpeg";
import JPEG313 from "./assets/Honeymoon/2022-08-09/313.jpeg";
import JPEG314 from "./assets/Honeymoon/2022-08-09/314.mp4";
import JPEG315 from "./assets/Honeymoon/2022-08-09/315.jpeg";
import JPEG316 from "./assets/Honeymoon/2022-08-09/316.jpeg";
import JPEG317 from "./assets/Honeymoon/2022-08-09/317.jpeg";
import JPEG318 from "./assets/Honeymoon/2022-08-09/318.jpeg";
import JPEG319 from "./assets/Honeymoon/2022-08-09/319.jpeg";
import JPEG320 from "./assets/Honeymoon/2022-08-09/320.jpeg";
import JPEG321 from "./assets/Honeymoon/2022-08-09/321.jpeg";
import JPEG322 from "./assets/Honeymoon/2022-08-09/322.jpeg";
import JPEG323 from "./assets/Honeymoon/2022-08-09/323.jpeg";
import JPEG324 from "./assets/Honeymoon/2022-08-09/324.jpeg";
import JPEG325 from "./assets/Honeymoon/2022-08-09/325.mp4";
import JPEG326 from "./assets/Honeymoon/2022-08-09/326.jpeg";
import JPEG327 from "./assets/Honeymoon/2022-08-09/327.jpeg";
import JPEG328 from "./assets/Honeymoon/2022-08-09/328.jpeg";
import JPEG329 from "./assets/Honeymoon/2022-08-09/329.jpeg";
import JPEG330 from "./assets/Honeymoon/2022-08-09/330.jpeg";
import JPEG331 from "./assets/Honeymoon/2022-08-09/331.jpeg";
import JPEG332 from "./assets/Honeymoon/2022-08-09/332.jpeg";
import JPEG333 from "./assets/Honeymoon/2022-08-09/333.jpeg";
import JPEG334 from "./assets/Honeymoon/2022-08-09/334.jpeg";
import JPEG335 from "./assets/Honeymoon/2022-08-09/335.mp4";
import JPEG336 from "./assets/Honeymoon/2022-08-09/336.jpeg";
import JPEG337 from "./assets/Honeymoon/2022-08-09/337.jpeg";
import JPEG338 from "./assets/Honeymoon/2022-08-09/338.jpeg";
import JPEG339 from "./assets/Honeymoon/2022-08-09/339.jpeg";
import JPEG340 from "./assets/Honeymoon/2022-08-09/340.jpeg";
import JPEG341 from "./assets/Honeymoon/2022-08-09/341.mp4";
import JPEG342 from "./assets/Honeymoon/2022-08-09/342.jpeg";
import JPEG343 from "./assets/Honeymoon/2022-08-09/343.jpeg";
import JPEG344 from "./assets/Honeymoon/2022-08-09/344.jpeg";
import JPEG345 from "./assets/Honeymoon/2022-08-09/345.mp4";
import JPEG346 from "./assets/Honeymoon/2022-08-09/346.jpeg";
import JPEG347 from "./assets/Honeymoon/2022-08-09/347.jpeg";
import JPEG348 from "./assets/Honeymoon/2022-08-09/348.jpeg";
import JPEG349 from "./assets/Honeymoon/2022-08-09/349.jpeg";
import JPEG350 from "./assets/Honeymoon/2022-08-09/350.jpeg";
import JPEG351 from "./assets/Honeymoon/2022-08-09/351.jpeg";
import JPEG352 from "./assets/Honeymoon/2022-08-09/352.jpeg";
import JPEG353 from "./assets/Honeymoon/2022-08-09/353.jpeg";
import JPEG354 from "./assets/Honeymoon/2022-08-09/354.jpeg";
import JPEG355 from "./assets/Honeymoon/2022-08-09/355.jpeg";
import JPEG356 from "./assets/Honeymoon/2022-08-09/356.jpeg";
import JPEG357 from "./assets/Honeymoon/2022-08-09/357.jpeg";
import JPEG358 from "./assets/Honeymoon/2022-08-09/358.mp4";
import JPEG359 from "./assets/Honeymoon/2022-08-09/359.jpeg";
import JPEG360 from "./assets/Honeymoon/2022-08-09/360.jpeg";
import JPEG361 from "./assets/Honeymoon/2022-08-09/361.mp4";
import JPEG362 from "./assets/Honeymoon/2022-08-09/362.jpeg";
import JPEG363 from "./assets/Honeymoon/2022-08-09/363.jpeg";
import JPEG364 from "./assets/Honeymoon/2022-08-09/364.mp4";
import JPEG365 from "./assets/Honeymoon/2022-08-09/365.mp4";
import JPEG366 from "./assets/Honeymoon/2022-08-09/366.mp4";
import JPEG367 from "./assets/Honeymoon/2022-08-09/367.jpeg";
import JPEG368 from "./assets/Honeymoon/2022-08-09/368.jpeg";
import JPEG369 from "./assets/Honeymoon/2022-08-09/369.jpeg";
import JPEG370 from "./assets/Honeymoon/2022-08-09/370.jpeg";
import JPEG371 from "./assets/Honeymoon/2022-08-09/371.jpeg";
import JPEG372 from "./assets/Honeymoon/2022-08-09/372.jpeg";
import JPEG373 from "./assets/Honeymoon/2022-08-09/373.jpeg";
import JPEG374 from "./assets/Honeymoon/2022-08-09/374.jpeg";
import JPEG375 from "./assets/Honeymoon/2022-08-09/375.jpeg";
import JPEG376 from "./assets/Honeymoon/2022-08-09/376.jpeg";
import JPEG377 from "./assets/Honeymoon/2022-08-09/377.jpeg";
import JPEG378 from "./assets/Honeymoon/2022-08-09/378.mp4";
import JPEG379 from "./assets/Honeymoon/2022-08-09/379.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src: JPEG295,
    width: 4,
    height: 3
  },
  {
    src: JPEG296,
    width: 4,
    height: 3
  },
  {
    src: JPEG297,
    width: 4,
    height: 3
  },
  {
    src: JPEG298,
    width: 4,
    height: 3
  },
  {
    src: JPEG299,
    width: 4,
    height: 3
  },
  {
    src: JPEG300,
    width: 4,
    height: 3
  },
  {
    src: JPEG301,
    width: 4,
    height: 3
  },
  {
    src: JPEG302,
    width: 4,
    height: 3
  },
  {
    src: JPEG303,
    width: 4,
    height: 3
  },
  {
    src: JPEG304,
    width: 4,
    height: 3
  },
  {
    src: JPEG305,
    width: 4,
    height: 3
  },
  {
    src: JPEG306,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG307,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG308,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG309,
    width: 4,
    height: 3
  },
  {
    src: JPEG310,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG311,
    width: 4,
    height: 3
  },
  {
    src: JPEG312,
    width: 4,
    height: 3
  },
  {
    src: JPEG313,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG314,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG315,
    width: 4,
    height: 3
  },
  {
    src: JPEG316,
    width: 4,
    height: 3
  },
  {
    src: JPEG317,
    width: 4,
    height: 3
  },
  {
    src: JPEG318,
    width: 4,
    height: 3
  },
  {
    src: JPEG319,
    width: 4,
    height: 3
  },
  {
    src: JPEG320,
    width: 4,
    height: 3
  },
  {
    src: JPEG321,
    width: 4,
    height: 3
  },
  {
    src: JPEG322,
    width: 4,
    height: 3
  },
  {
    src: JPEG323,
    width: 4,
    height: 3
  },
  {
    src: JPEG324,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG325,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG326,
    width: 4,
    height: 3
  },
  {
    src: JPEG327,
    width: 4,
    height: 3
  },
  {
    src: JPEG328,
    width: 4,
    height: 3
  },
  {
    src: JPEG329,
    width: 4,
    height: 3
  },
  {
    src: JPEG330,
    width: 4,
    height: 3
  },
  {
    src: JPEG331,
    width: 4,
    height: 3
  },
  {
    src: JPEG332,
    width: 4,
    height: 3
  },
  {
    src: JPEG333,
    width: 4,
    height: 3
  },
  {
    src: JPEG334,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG335,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG336,
    width: 4,
    height: 3
  },
  {
    src: JPEG337,
    width: 4,
    height: 3
  },
  {
    src: JPEG338,
    width: 4,
    height: 3
  },
  {
    src: JPEG339,
    width: 4,
    height: 3
  },
  {
    src: JPEG340,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG341,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG342,
    width: 4,
    height: 3
  },
  {
    src: JPEG343,
    width: 4,
    height: 3
  },
  {
    src: JPEG344,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG345,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG346,
    width: 4,
    height: 3
  },
  {
    src: JPEG347,
    width: 4,
    height: 3
  },
  {
    src: JPEG348,
    width: 4,
    height: 3
  },
  {
    src: JPEG349,
    width: 4,
    height: 3
  },
  {
    src: JPEG350,
    width: 4,
    height: 3
  },
  {
    src: JPEG351,
    width: 4,
    height: 3
  },
  {
    src: JPEG352,
    width: 4,
    height: 3
  },
  {
    src: JPEG353,
    width: 4,
    height: 3
  },
  {
    src: JPEG354,
    width: 4,
    height: 3
  },
  {
    src: JPEG355,
    width: 4,
    height: 3
  },
  {
    src: JPEG356,
    width: 4,
    height: 3
  },
  {
    src: JPEG357,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG358,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG359,
    width: 4,
    height: 3
  },
  {
    src: JPEG360,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG361,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG362,
    width: 4,
    height: 3
  },
  {
    src: JPEG363,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG364,
    width: 4,
    height: 3
  },
  {
    src: JPEG365,
    width: 4,
    height: 3
  },
  {
    src: JPEG366,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG367,
    width: 4,
    height: 3
  },
  {
    src: JPEG368,
    width: 4,
    height: 3
  },
  {
    src: JPEG369,
    width: 4,
    height: 3
  },
  {
    src: JPEG370,
    width: 4,
    height: 3
  },
  {
    src: JPEG371,
    width: 4,
    height: 3
  },
  {
    src: JPEG372,
    width: 4,
    height: 3
  },
  {
    src: JPEG373,
    width: 4,
    height: 3
  },
  {
    src: JPEG374,
    width: 4,
    height: 3
  },
  {
    src: JPEG375,
    width: 4,
    height: 3
  },
  {
    src: JPEG376,
    width: 4,
    height: 3
  },
  {
    src: JPEG377,
    width: 4,
    height: 3
  },
  /*{
    src: JPEG378,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG379,
    width: 4,
    height: 3
  }
];
