
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG138 from "./assets/Honeymoon/2022-08-07/138.mp4";
import JPEG139 from "./assets/Honeymoon/2022-08-07/139.jpeg";
import JPEG140 from "./assets/Honeymoon/2022-08-07/140.jpeg";
import JPEG141 from "./assets/Honeymoon/2022-08-07/141.jpeg";
import JPEG142 from "./assets/Honeymoon/2022-08-07/142.jpeg";
import JPEG143 from "./assets/Honeymoon/2022-08-07/143.jpeg";
import JPEG144 from "./assets/Honeymoon/2022-08-07/144.jpeg";
import JPEG145 from "./assets/Honeymoon/2022-08-07/145.jpeg";
import JPEG146 from "./assets/Honeymoon/2022-08-07/146.jpeg";
import JPEG147 from "./assets/Honeymoon/2022-08-07/147.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  /*{
    src: JPEG138,
    width: 4,
    height: 3
  },*/
  {
    src: JPEG139,
    width: 4,
    height: 3
  },
  {
    src: JPEG140,
    width: 4,
    height: 3
  },
  {
    src: JPEG141,
    width: 4,
    height: 3
  },
  {
    src: JPEG142,
    width: 4,
    height: 3
  },
  {
    src: JPEG143,
    width: 4,
    height: 3
  },
  {
    src: JPEG144,
    width: 4,
    height: 3
  },
  {
    src: JPEG145,
    width: 4,
    height: 3
  },
  {
    src: JPEG146,
    width: 4,
    height: 3
  },
  {
    src: JPEG147,
    width: 4,
    height: 3
  }
];
