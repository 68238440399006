

import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import images
import JPEG54 from "./assets/Honeymoon/2022-08-05/54.jpeg";
import JPEG55 from "./assets/Honeymoon/2022-08-05/55.jpeg";
import JPEG56 from "./assets/Honeymoon/2022-08-05/56.jpeg";
import JPEG57 from "./assets/Honeymoon/2022-08-05/57.jpeg";
import JPEG58 from "./assets/Honeymoon/2022-08-05/58.jpeg";
import JPEG59 from "./assets/Honeymoon/2022-08-05/59.jpeg";
import JPEG60 from "./assets/Honeymoon/2022-08-05/60.jpeg";
import JPEG61 from "./assets/Honeymoon/2022-08-05/61.jpeg";
import JPEG62 from "./assets/Honeymoon/2022-08-05/62.jpeg";
import JPEG63 from "./assets/Honeymoon/2022-08-05/63.jpeg";
import JPEG64 from "./assets/Honeymoon/2022-08-05/64.jpeg";
import JPEG65 from "./assets/Honeymoon/2022-08-05/65.jpeg";
import JPEG66 from "./assets/Honeymoon/2022-08-05/66.jpeg";
import JPEG67 from "./assets/Honeymoon/2022-08-05/67.jpeg";
import JPEG68 from "./assets/Honeymoon/2022-08-05/68.jpeg";
import JPEG69 from "./assets/Honeymoon/2022-08-05/69.jpeg";
import JPEG70 from "./assets/Honeymoon/2022-08-05/70.jpeg";
import JPEG71 from "./assets/Honeymoon/2022-08-05/71.jpeg";
import JPEG72 from "./assets/Honeymoon/2022-08-05/72.jpeg";
import JPEG73 from "./assets/Honeymoon/2022-08-05/73.jpeg";
import JPEG74 from "./assets/Honeymoon/2022-08-05/74.jpeg";
import JPEG75 from "./assets/Honeymoon/2022-08-05/75.jpeg";
import JPEG76 from "./assets/Honeymoon/2022-08-05/76.jpeg";
import JPEG77 from "./assets/Honeymoon/2022-08-05/77.jpeg";
import JPEG78 from "./assets/Honeymoon/2022-08-05/78.jpeg";
import JPEG79 from "./assets/Honeymoon/2022-08-05/79.jpeg";
import JPEG80 from "./assets/Honeymoon/2022-08-05/80.jpeg";
import JPEG81 from "./assets/Honeymoon/2022-08-05/81.mp4";
import JPEG82 from "./assets/Honeymoon/2022-08-05/82.jpeg";
import JPEG83 from "./assets/Honeymoon/2022-08-05/83.jpeg";
import JPEG84 from "./assets/Honeymoon/2022-08-05/84.jpeg";
import JPEG85 from "./assets/Honeymoon/2022-08-05/85.jpeg";
import JPEG86 from "./assets/Honeymoon/2022-08-05/86.jpeg";
import JPEG87 from "./assets/Honeymoon/2022-08-05/87.jpeg";
import JPEG88 from "./assets/Honeymoon/2022-08-05/88.jpeg";
import JPEG89 from "./assets/Honeymoon/2022-08-05/89.jpeg";
import JPEG90 from "./assets/Honeymoon/2022-08-05/90.jpeg";
import JPEG91 from "./assets/Honeymoon/2022-08-05/91.jpeg";
import JPEG92 from "./assets/Honeymoon/2022-08-05/92.jpeg";
import JPEG93 from "./assets/Honeymoon/2022-08-05/93.mp4";
import JPEG94 from "./assets/Honeymoon/2022-08-05/94.jpeg";
import JPEG95 from "./assets/Honeymoon/2022-08-05/95.jpeg";
import JPEG96 from "./assets/Honeymoon/2022-08-05/96.jpeg";
import JPEG97 from "./assets/Honeymoon/2022-08-05/97.jpeg";
import JPEG98 from "./assets/Honeymoon/2022-08-05/98.jpeg";
import JPEG99 from "./assets/Honeymoon/2022-08-05/99.jpeg";
import JPEG100 from "./assets/Honeymoon/2022-08-05/100.jpeg";
import JPEG101 from "./assets/Honeymoon/2022-08-05/101.jpeg";
import JPEG102 from "./assets/Honeymoon/2022-08-05/102.jpeg";
import JPEG103 from "./assets/Honeymoon/2022-08-05/103.jpeg";
import JPEG104 from "./assets/Honeymoon/2022-08-05/104.jpeg";
import JPEG105 from "./assets/Honeymoon/2022-08-05/105.jpeg";
import JPEG106 from "./assets/Honeymoon/2022-08-05/106.jpeg";
import JPEG107 from "./assets/Honeymoon/2022-08-05/107.jpeg";
import JPEG108 from "./assets/Honeymoon/2022-08-05/108.jpeg";
import JPEG109 from "./assets/Honeymoon/2022-08-05/109.jpeg";
import JPEG110 from "./assets/Honeymoon/2022-08-05/110.jpeg";
import JPEG111 from "./assets/Honeymoon/2022-08-05/111.jpeg";
import JPEG112 from "./assets/Honeymoon/2022-08-05/112.jpeg";
import JPEG113 from "./assets/Honeymoon/2022-08-05/113.jpeg";
import JPEG114 from "./assets/Honeymoon/2022-08-05/114.jpeg";
import JPEG115 from "./assets/Honeymoon/2022-08-05/115.jpeg";
import JPEG116 from "./assets/Honeymoon/2022-08-05/116.jpeg";
import JPEG117 from "./assets/Honeymoon/2022-08-05/117.jpeg";

export default function Honeymoon() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

const photos = [
  {
    src:JPEG54,
    width: 4,
    height: 3
  },
  {
    src:JPEG55,
    width: 4,
    height: 3
  },
  {
    src:JPEG56,
    width: 4,
    height: 3
  },
  {
    src:JPEG57,
    width: 4,
    height: 3
  },
  {
    src:JPEG58,
    width: 4,
    height: 3
  },
  {
    src:JPEG59,
    width: 4,
    height: 3
  },
  {
    src:JPEG60,
    width: 4,
    height: 3
  },
  {
    src:JPEG61,
    width: 4,
    height: 3
  },
  {
    src:JPEG62,
    width: 4,
    height: 3
  },
  {
    src:JPEG63,
    width: 4,
    height: 3
  },
  {
    src:JPEG64,
    width: 4,
    height: 3
  },
  {
    src:JPEG65,
    width: 4,
    height: 3
  },
  {
    src:JPEG66,
    width: 4,
    height: 3
  },
  {
    src:JPEG67,
    width: 4,
    height: 3
  },
  {
    src:JPEG68,
    width: 4,
    height: 3
  },
  {
    src:JPEG69,
    width: 4,
    height: 3
  },
  {
    src:JPEG70,
    width: 4,
    height: 3
  },
  {
    src:JPEG71,
    width: 4,
    height: 3
  },
  {
    src:JPEG72,
    width: 4,
    height: 3
  },
  {
    src:JPEG73,
    width: 4,
    height: 3
  },
  {
    src:JPEG74,
    width: 4,
    height: 3
  },
  {
    src:JPEG75,
    width: 4,
    height: 3
  },
  {
    src:JPEG76,
    width: 4,
    height: 3
  },
  {
    src:JPEG77,
    width: 4,
    height: 3
  },
  {
    src:JPEG78,
    width: 4,
    height: 3
  },
  {
    src:JPEG79,
    width: 4,
    height: 3
  },
  {
    src:JPEG80,
    width: 4,
    height: 3
  },
  /*{
    src:JPEG81,
    width: 4,
    height: 3
  },*/
  {
    src:JPEG82,
    width: 4,
    height: 3
  },
  {
    src:JPEG83,
    width: 4,
    height: 3
  },
  {
    src:JPEG84,
    width: 4,
    height: 3
  },
  {
    src:JPEG85,
    width: 4,
    height: 3
  },
  {
    src:JPEG86,
    width: 4,
    height: 3
  },
  {
    src:JPEG87,
    width: 4,
    height: 3
  },
  {
    src:JPEG88,
    width: 4,
    height: 3
  },
  {
    src:JPEG89,
    width: 4,
    height: 3
  },
  {
    src:JPEG90,
    width: 4,
    height: 3
  },
  {
    src:JPEG91,
    width: 4,
    height: 3
  },
  {
    src:JPEG92,
    width: 4,
    height: 3
  },
  /*{
    src:JPEG93,
    width: 4,
    height: 3
  },*/
  {
    src:JPEG94,
    width: 4,
    height: 3
  },
  {
    src:JPEG95,
    width: 4,
    height: 3
  },
  {
    src:JPEG96,
    width: 4,
    height: 3
  },
  {
    src:JPEG97,
    width: 4,
    height: 3
  },
  {
    src:JPEG98,
    width: 4,
    height: 3
  },
  {
    src:JPEG99,
    width: 4,
    height: 3
  },
  {
    src:JPEG100,
    width: 4,
    height: 3
  },
  {
    src:JPEG101,
    width: 4,
    height: 3
  },
  {
    src:JPEG102,
    width: 4,
    height: 3
  },
  {
    src:JPEG103,
    width: 4,
    height: 3
  },
  {
    src:JPEG104,
    width: 4,
    height: 3
  },
  {
    src:JPEG105,
    width: 4,
    height: 3
  },
  {
    src:JPEG106,
    width: 4,
    height: 3
  },
  {
    src:JPEG107,
    width: 4,
    height: 3
  },
  {
    src:JPEG108,
    width: 4,
    height: 3
  },
  {
    src:JPEG109,
    width: 4,
    height: 3
  },
  {
    src:JPEG110,
    width: 4,
    height: 3
  },
  {
    src:JPEG111,
    width: 4,
    height: 3
  },
  {
    src:JPEG112,
    width: 4,
    height: 3
  },
  {
    src:JPEG113,
    width: 4,
    height: 3
  },
  {
    src:JPEG114,
    width: 4,
    height: 3
  },
  {
    src:JPEG115,
    width: 4,
    height: 3
  },
  {
    src:JPEG116,
    width: 4,
    height: 3
  },
  {
    src:JPEG117,
    width: 4,
    height: 3
  }
];
